/** Pinepointers 3: Brothers **/

import React from "react";
import { preloadImages, scaleFullscreen, showText, hideText, positionText, resetAnimationDelay } from "../../helpers"
import PrevNext from "../../components/navigation/prevnext"
import SEO from "../../components/seo"
import { gsap, Circ } from "gsap";

let globalState, data, preloadData;

class PinepointersPage3 extends React.Component {

    constructor(props) {
        super(props);

        //update global state and assets data
        globalState = this.props.location.state;
        data = globalState.assets[3];
        preloadData = globalState.assets[4];

        //refs
        this.fullscreenBg = null;

    }

    state = {
        textVisible: false,
    }




    componentDidMount = () => {

        setTimeout(() => {
            
            //1. animate highlighters
            gsap.to(this.refs.highlighterWrapper1Ref, 1, { scale: 1, ease: Circ.easeOut, delay: 0.5 });
            gsap.to(this.refs.highlighterWrapper2Ref, 1, { scale: 1, ease: Circ.easeOut, delay: 1 });

            //3. Animate in Badge
            gsap.to(this.refs.brothersBadgeRef, 0.75, { x: 0, y: 0, rotate: 0, ease: Circ.easeOut, delay: 1.5 });

        }, globalState.transitionDuration);


        //position text
        positionText(this.refs.text1Ref, "right", "top", globalState.textPosition.rightTop);


        //show text
        gsap.to(this.refs.text1Ref, 0.15, { autoAlpha: 1.0 });

        //add base track
        globalState.baseAudioRef.updateTrack(2, globalState.cdnUrl + data.soundfile2);
        
        //Update dimensions
        this.updateDimensions();

        //Add resize listener
        window.addEventListener("resize", this.updateDimensions);

        //Preload assets from next page
        preloadImages([
            globalState.cdnUrl + preloadData.text1, 
            globalState.cdnUrl + preloadData.badge,
            globalState.cdnUrl + preloadData.highlight, 
            globalState.cdnUrl + preloadData.background,
            globalState.cdnUrl + preloadData.creature1,
            globalState.cdnUrl + preloadData.creature2,
            globalState.cdnUrl + preloadData.creature3,
            globalState.cdnUrl + preloadData.creature4,
            globalState.cdnUrl + preloadData.creature5,
            globalState.cdnUrl + preloadData.creature6,
            globalState.cdnUrl + preloadData.creature7,
            globalState.cdnUrl + preloadData.creature8,
            globalState.cdnUrl + preloadData.creature9,
        ], this.preloadCallback);

    }

    preloadCallback = () => {
        this.refs.prevNextRef.showPrevNextBtns(globalState.showPrevNextDelay);
    }



    componentWillUnmount() {
        //Kill any tweens
        gsap.killTweensOf(this.refs.text1Ref);

        window.removeEventListener("resize", this.updateDimensions);
    }


    toggleTextDisplay = (event) => {

        //toggle text visibility
        this.setState({ textVisible: !this.state.textVisible });

        //reset the animation delay
        resetAnimationDelay();

        if (this.state.textVisible) {
            //show text
            showText(this.refs.text1Ref, "right", "top", globalState.textPosition.rightTop);


        } else {
            //hide text
            hideText(this.refs.text1Ref, "right");

        }
    }

    isTransitioning = () => {
        //pause animations
    }

    updateDimensions = () => {
        console.log('updateDimensions');
        //background native size is 1288 x 724
        scaleFullscreen(this.refs.fullscreenBgRef, window.innerWidth, window.innerHeight, 1288, 724, false);

    }

   

    render() {

        return (

            <>

                <link rel="prefetch" href="/pinepointers/4" />

                <PrevNext globalState={globalState} ref="prevNextRef"
                    nextLocation="/pinepointers/4" prevLocation="/pinepointers/2"
                    isTransitioning={this.isTransitioning} />
                {data && (
                <div className="fullpage-wrapper">
                    <SEO title="Pine Point - Pinepointers" />

                    <div className="text-wrapper">
                        <div className="text text--right-top" ref="text1Ref">
                            <input type="image" src={globalState.cdnUrl + data.text1} style={{ marginRight: "0", marginTop: "23vh" }} onClick={this.toggleTextDisplay} alt="Wayne and Lyle Hryniuk were separated by 8 years. Wayne was always headed for the mine. Lyle went the opposite direction, as younger brothers often do. The closest he came was a summer job in high school as a guide for mine tourists. Every place has its current, its countercurrent." />
                        </div>
                    </div>

                    <div className="fullscreen-bg pinepointers-bg" ref="fullscreenBgRef">
                        <img src={globalState.cdnUrl + data.background} alt="background" className="background" />
                        <div className="highlighterWrapper" ref="highlighterWrapper1Ref" style={{ left: '60px', top: '402px', width: '171px', height: '27px' }}>
                            <img src={globalState.cdnUrl + data.highlight1} alt="highlight" className="highlight" />
                        </div>
                        <div className="highlighterWrapper" ref="highlighterWrapper2Ref" style={{ left: '470px', top: '343px', width: '214px', height: '26px' }}>
                            <img src={globalState.cdnUrl + data.highlight2} alt="highlight" className="highlight" />
                        </div>
                        <img src={globalState.cdnUrl + data.badge} className="badge brothersBadge" ref="brothersBadgeRef" alt="The Brothers" style={{ left: '216px', top: '184px' }} />
                    </div>
                </div>
                )}
            </>
        );
    }


};

export default PinepointersPage3
